import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout/layout"
import SEO from "components/seo"
import { seoDataFromPage } from "components/utils/data-formatting"

const IndexPage = ({ pageContext, data: queryData, path }) => {
  const meta = seoDataFromPage(queryData.wordpressPage)
  return (
    <>
      <SEO title={pageContext.title} meta={meta} />
      <Layout path={path} />
    </>
  )
}

export default IndexPage

// page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...SEOData
    }
  }
`
